
import { defineComponent, ref, computed } from 'vue'

import Token from '@web/common/token'
import { useStore } from '@web/store'

export default defineComponent({
  setup () {
    const store = useStore()

    const open = ref(false)
    const user = computed(() => store.getters['user/user'])
    const menu = computed(() => store.getters['menu/general'])
    const urlAdmin = computed(() => `/admin/login?token=${Token.get()}`)

    function openModal () {
      open.value = true
      document.body.style.overflow = 'hidden'
    }
    function closeModal () {
      open.value = false
      document.body.style.overflow = ''
    }
    function logout () {
      store.dispatch('logout')
    }

    return {
      open,
      user,
      menu,
      urlAdmin,

      openModal,
      closeModal,
      logout
    }
  }
})
