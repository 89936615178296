
import { defineComponent } from 'vue'
import { getUrlWithAuthParamsCommunity } from '@web/common/Utils'
import { communityUrlApi } from '@web/common/ExternalConfig/getters'
import i18n from '@web/plugins/i18n'

export default defineComponent({
  name: 'AsGoToCommunity',
  props: {
    as: {
      type: String as () => 'button' | 'link',
      default: 'button'
    },
    text: {
      type: String,
      default: i18n.global.t('goToCommunity')
    }
  },
  setup () {
    return {
      isShow: !!communityUrlApi(),
      url: getUrlWithAuthParamsCommunity(communityUrlApi())
    }
  }
})
