import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "asCourseContent" }
const _hoisted_2 = { class: "asCourseContent_continueCourses" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mq_responsive = _resolveComponent("mq-responsive")!
  const _component_as_renew_course = _resolveComponent("as-renew-course")!
  const _component_as_course_preview = _resolveComponent("as-course-preview")!
  const _component_as_courses = _resolveComponent("as-courses")!
  const _component_as_button = _resolveComponent("as-button")!
  const _component_as_empty = _resolveComponent("as-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_mq_responsive, { target: "mobile-tablet" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('courseContinue')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_as_courses, { "courses-streams": _ctx.coursesStreams }, {
      default: _withCtx((props) => [
        _createVNode(_component_as_course_preview, {
          "data-cy": "asCoursePreview",
          "course-stream": props.courseStream
        }, {
          continue: _withCtx(() => [
            _createVNode(_component_as_renew_course, {
              stream: props.courseStream.stream
            }, null, 8, ["stream"])
          ]),
          _: 2
        }, 1032, ["course-stream"])
      ]),
      _: 1
    }, 8, ["courses-streams"]),
    (_ctx.coursesStreams.length === 0)
      ? (_openBlock(), _createBlock(_component_as_empty, {
          key: 0,
          class: "-inner"
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('notFoundCourses')), 1),
            _createVNode(_component_as_button, {
              onClick: _cache[0] || (_cache[0] = () => _ctx.window.location.href = '/')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('openCatalogCourses')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}