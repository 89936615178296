
import { defineComponent, ref, computed } from 'vue'
import AsRenewCourseMobile from '@web/components/AsRenewCourseMobile.vue'
import AsModalCriticalRenew from '@web/components/AsModalCriticalRenew.vue'

import { useStore } from '@web/store'
import { useRouter } from 'vue-router'
import useStatusRenew from '@web/composition/useStatusRenew'

import LocalStorage from '@web/common/LocalStorage'
import { Stream } from '@web/store/types/modules/stream'
import Analytics from '@web/services/Analytics/Analytics'
import STREAM_STATUS from '@web/consts/StreamStatus'
import { getNoteCountDayRemaining, getUrlWithAuthParams } from '@web/common/Utils'
import i18n from '@web/plugins/i18n'
import Swal from 'sweetalert2'
import { EXTEND_MODEL } from '@web/consts/CourseExtentends'
import urlParse from 'url-parse'

const DAY = 86400000

export default defineComponent({
  components: {
    AsModalCriticalRenew,
    AsRenewCourseMobile
  },
  props: {
    stream: {
      type: Object as () => Stream,
      required: true
    },
    course: {
      type: Object as () => Course,
      required: true
    }
  },
  setup (props) {
    const {
      isEnded,
      isWarning,
      isCritical,
      isFreezing
    } = useStatusRenew(props.stream)
    const store = useStore()
    const router = useRouter()
    const showRenewCourse = ref(false)
    const showModalCritical = ref(false)

    const isShowAccessText = computed(() => {
      if (props.stream.status === STREAM_STATUS.NOT_STARTED) {
        return true
      }

      return !props.stream.paymentSubscriptionId
    })

    const progress = props.stream.progress.progress || 0
    const canContinueCourse = props.stream.status === STREAM_STATUS.OPEN
    const continueUrl = canContinueCourse
      ? {
          name: 'calendar',
          params: {
            courseId: props.stream.courseId,
            streamId: props.stream.id
          }
        }
      : ''
    const courseImage: string = props.course.image?.small || ''

    const savedTime = {
      _key: `acProject_modal_critical_${props.stream.id}`,
      get (): number {
        return Number(LocalStorage.get(this._key) || 0)
      },
      set (newTime: string): void {
        LocalStorage.set(this._key, newTime)
      }
    }

    function goToContinue (): void {
      const route = {
        name: 'calendar',
        params: {
          courseId: String(props.stream.courseId),
          streamId: String(props.stream.id)
        }
      }

      router.push(route)
    }

    function onShowRenewModal () {
      showModalCritical.value = false

      if (props.course.extendModel !== EXTEND_MODEL.DISABLED_EXTEND) {
        Analytics.send({
          element_type: 'other',
          element_id: 'prolongation',
          action: 'click'
        })
        showRenewCourse.value = true
      }
    }

    function remindLater () {
      Analytics.send({
        element_type: 'other',
        element_id: 'remindLater',
        action: 'click'
      })
      savedTime.set(Date.now().toString())
      router.push(continueUrl)
    }

    async function unfreezeCourse () {
      const result = await Swal.fire({
        title: i18n.global.t('areYouSureUnfreeze'),
        confirmButtonText: i18n.global.t('sure'),
        cancelButtonText: i18n.global.t('no'),
        showCancelButton: true
      })
      if (result.isConfirmed) {
        await store.dispatch('stream/unfreeze', {
          streamId: props.stream.id
        })
        goToContinue()
      }
    }

    function openCourse () {
      if (props.stream.status === STREAM_STATUS.NOT_STARTED) {
        return
      }

      if (props.stream.status === STREAM_STATUS.FREEZE) {
        unfreezeCourse()
        return
      }

      const isShowCriticalPopup = !(savedTime.get() && ((Date.now() - savedTime.get()) < DAY))
      if (!canContinueCourse) {
        if (props.stream.isOptionalPurchase) {
          router.push({ name: 'subscriptions', params: { warning: 'true' } })
        } else if (props.course.extendModel === EXTEND_MODEL.NEW_BUY_EXTEND && props.course.extendPageUrl) {
          const urlInstance = urlParse(props.course.extendPageUrl, true)
          urlInstance.set('query', {
            ...urlInstance.query,
            utm_source: 'lk',
            utm_medium: 'extend_button'
          })
          window.open(getUrlWithAuthParams(urlInstance.toString()), '_blank')?.focus()
        } else {
          onShowRenewModal()
        }
      } else if (isCritical && isShowCriticalPopup && props.course.extendModel === EXTEND_MODEL.STANDARD_EXTEND && !props.stream.paymentSubscriptionId) {
        showModalCritical.value = true
      } else {
        router.push(continueUrl)
      }
    }

    const statusClasses = {
      '-ended': isEnded,
      '-warning': isWarning,
      '-critical': isCritical,
      '-frozen': isFreezing,
      '-disabled': !canContinueCourse,
      '-finished': progress === 100
    }
    const isShowRenewBtn = computed(() => {
      if (props.stream.status === STREAM_STATUS.ENDED || props.stream.status === STREAM_STATUS.STOPPED) {
        return props.course?.extendModel !== EXTEND_MODEL.DISABLED_EXTEND
      }
      return true
    })

    const continueBtnText = computed(() => {
      if (props.stream.status === STREAM_STATUS.FREEZE) {
        return i18n.global.t('resume')
      }
      if (props.stream.status === STREAM_STATUS.ENDED || props.stream.status === STREAM_STATUS.STOPPED) {
        return props.course?.extendModel === EXTEND_MODEL.NEW_BUY_EXTEND ? i18n.global.t('renew') : i18n.global.t('renew')
      }
      return i18n.global.t('continue')
    })

    return {
      isShowAccessText,
      accessText: getNoteCountDayRemaining(props.stream, false),
      statusClasses,
      showRenewCourse,
      showModalCritical,
      continueUrl,
      progress,
      courseImage,
      goToContinue,
      onShowRenewModal,
      remindLater,
      openCourse,
      isShowRenewBtn,
      continueBtnText
    }
  }
})
