
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    coursesStreams: {
      type: [Array, Object],
      default: () => []
    }
  }
})
