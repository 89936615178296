import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "asCoursePreview_caption" }
const _hoisted_3 = { class: "asCoursePreview_author" }
const _hoisted_4 = { class: "asCoursePreview_wrap" }
const _hoisted_5 = { class: "asCoursePreview_row" }
const _hoisted_6 = {
  key: 0,
  class: "asCoursePreview_tags"
}
const _hoisted_7 = { class: "asCoursePreview_progress" }
const _hoisted_8 = { class: "asCoursePreview_content" }
const _hoisted_9 = {
  class: "asCoursePreview_title",
  "data-cy": "course_title"
}
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["data-analytics-element-name"]
const _hoisted_12 = ["data-analytics-element-name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_as_note_count_day = _resolveComponent("as-note-count-day")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["asCoursePreview", { '-loading': !_ctx.loaded, '-disabled': !_ctx.canContinueCourse }])
  }, [
    _createVNode(_component_router_link, {
      to: _ctx.continueUrl,
      class: _normalizeClass(["asCoursePreview_header", { '-black': _ctx.courseBackground }]),
      "data-analytics": "",
      "data-analytics-element-type": "other",
      "data-analytics-element-id": "course_preview",
      "data-analytics-element-name": _ctx.courseStream.course.caption,
      "data-cy": `coursePreview_${_ctx.courseStream.stream.id}`
    }, {
      default: _withCtx(() => [
        (_ctx.courseImage)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "asCoursePreview_background",
              style: _normalizeStyle(_ctx.style)
            }, [
              _createElementVNode("img", {
                src: _ctx.courseImage,
                alt: "",
                class: "asCoursePreview_img"
              }, null, 8, _hoisted_1)
            ], 4))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.courseStream.course.caption), 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.courseStream.course.coachName), 1)
      ]),
      _: 1
    }, 8, ["to", "class", "data-analytics-element-name", "data-cy"]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_ctx.courseStream.course.tags && _ctx.courseStream.course.tags.length > 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.courseStream.course.tags.join(' | ')), 1))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.progress), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.courseStream.course.title), 1),
        (_ctx.isShowAccessText)
          ? (_openBlock(), _createBlock(_component_as_note_count_day, {
              key: 0,
              stream: _ctx.courseStream.stream
            }, null, 8, ["stream"]))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: "asCoursePreview_text",
          innerHTML: _ctx.courseStream.course.excerpt
        }, null, 8, _hoisted_10)
      ]),
      _renderSlot(_ctx.$slots, "menu"),
      (_ctx.canContinueCourse)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "asCoursePreview_button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToContinue && _ctx.goToContinue(...args))),
            "data-analytics": "",
            "data-analytics-element-type": "other",
            "data-analytics-element-id": "course_btn",
            "data-analytics-element-name": _ctx.courseStream.course.caption
          }, _toDisplayString(_ctx.$t('courseContinue')), 9, _hoisted_11))
        : _createCommentVNode("", true),
      (_ctx.showUnfreezeButton)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: "asCoursePreview_button -freeze",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.unfreezeCourse && _ctx.unfreezeCourse(...args))),
            "data-analytics": "",
            "data-analytics-element-type": "other",
            "data-analytics-element-id": "course_btn_unfreeze",
            "data-analytics-element-name": _ctx.courseStream.course.caption
          }, _toDisplayString(_ctx.$t('resume')), 9, _hoisted_12))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "continue")
    ])
  ], 2))
}