
import { defineComponent, onMounted } from 'vue'
import IconHeart from '@web/components/icons/IconHeart.vue'
import IconFavourite from '@web/components/icons/IconFavourite.vue'
import { Article } from '@web/types/Article'
import { useStore } from '@web/store'
import Time from '@web/common/Time'
import { likeArticle, dislikeArticle, unfavouriteArticle, favouriteArticle } from '@web/services/Analytics/events'
import Analytics from '@web/services/Analytics/Analytics'
import urlParse from 'url-parse'
import getBaseUrl from '@web/common/getBaseUrl'
import { getUrlWithAuthParams } from '@web/common/Utils'

export default defineComponent({
  name: 'AsArticleCard',
  props: {
    article: {
      type: Object as () => Article,
      required: true
    }
  },
  components: {
    IconHeart,
    IconFavourite
  },
  setup (props) {
    const store = useStore()

    const eventParams = {
      source: 'platform_article_feed',
      title: props.article.title
    }

    function toggleLike () {
      if (props.article.liked) {
        dislikeArticle(eventParams)
      } else {
        likeArticle(eventParams)
      }

      store.dispatch('article/toggleLike', {
        id: props.article.id,
        liked: !!props.article.liked
      })
    }

    function toggleFavourite () {
      if (props.article.isFavourite) {
        unfavouriteArticle(eventParams)
      } else {
        favouriteArticle(eventParams)
      }

      store.dispatch('article/toggleFavourite', {
        id: props.article.id,
        isFavourite: !!props.article.isFavourite
      })
    }

    function openArticle () {
      Analytics.send({
        action: 'articles_preview_click'
      })

      const url = urlParse(`${getBaseUrl() || ''}${props.article.url}`, true)
      url.set('query', {
        utm_source: 'lk',
        utm_medium: 'article_preview',
        utm_campaign: String(props.article.id),
        utm_term: props.article.title
      })
      window.location.href = getUrlWithAuthParams(url.toString())
    }

    onMounted(() => {
      Analytics.send({
        action: 'articles_preview'
      })
    })

    return {
      toggleLike,
      toggleFavourite,
      openArticle,
      date: Time(props.article.publishedAt).format('DD MMMM')
    }
  }
})
