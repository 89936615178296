
import { defineComponent, ref, computed, onMounted } from 'vue'
import { MqResponsive } from 'vue3-mq'

import AsHeader from '@web/components/headers/AsHeader.vue'
import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'
import AsMenu from '@web/components/AsMenu.vue'
import AsRow from '@web/components/AsRow.vue'
import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsFooter from '@web/components/AsFooter.vue'
import AsSpacer from '@web/components/AsSpacer.vue'
import AsTitle from '@web/components/AsTitle.vue'
import AsBreadcrumb from '@web/components/AsBreadcrumb.vue'
import AsPreloader from '@web/components/AsPreloader.vue'
import AsBanner from '@web/components/AsBanner.vue'
import AsCoursesContent from '@web/components/AsCoursesContent.vue'
import AsGoToCommunity from '@web/components/AsGoToCommunity.vue'
import AsCourseCard from '@web/components/AsCourseCard.vue'
import AsArticleCard from '@web/components/AsArticleCard.vue'
import AsContainer from '@web/components/AsContainer.vue'
import AsArticleContainer from '@web/components/AsArticleContainer.vue'
import AsBannersTop from '@web/components/AsBannersTop.vue'
import AsBannersCatalog from '@web/components/AsBannersCatalog.vue'
import AsPushPrompt from '@web/components/AsPushPrompt.vue'
import AsEmpty from '@web/components/AsEmpty.vue'

import i18n from '@web/plugins/i18n'
import sentry from '@web/common/sentry'
import Logger from '@web/common/Logger'
import Analytics from '@web/services/Analytics/Analytics'
import Swal from 'sweetalert2'

import StreamStatus from '@web/consts/StreamStatus'

import useCourses from '@web/composition/useCourses'
import { performanceEnd } from '@web/common/Utils'

import { useStore } from '@web/store'
import { Stream } from '@web/store/types/modules/stream'
import FirebasePerformance from '@web/services/firebasePerformance'

import { useRoute } from 'vue-router'

import { useMeta } from '@web/common/vueMeta'
import useArticles from '@web/composition/useArticles'
import { useCoursesBanners } from '@web/composition/useBanners'

type cMenu = Dictionary<{
  icon: string;
  name: string;
  link: string;
  type?: string;
  target?: string;
  note?: string;
}>

export default defineComponent({
  name: 'Courses',
  components: {
    AsBannersTop,
    AsBannersCatalog,
    MqResponsive,
    AsHeader,
    AsHeaderMobile,
    AsMenu,
    AsRow,
    AsHeaderMain,
    AsSpacer,
    AsFooter,
    AsTitle,
    AsBreadcrumb,
    AsPreloader,
    AsBanner,
    AsCoursesContent,
    AsArticleCard,
    AsContainer,
    AsArticleContainer,
    AsGoToCommunity,
    AsCourseCard,
    AsPushPrompt,
    AsEmpty
  },
  setup () {
    const store = useStore()
    const route = useRoute()

    const loaded = ref(true)
    const status = ref('')
    const bannersShown = ref({})
    const isOpenMenu = ref(false)
    const refContainerCourses = ref<typeof AsContainer | null>(null)

    const {
      courses
    } = useCourses()

    const {
      articles,
      fetchArticles,
      isShowArticles,
      previewLoaded
    } = useArticles()

    const user = computed(() => store.getters['user/user'])
    const username = computed(() => store.getters['user/username'])
    const menu = computed(() => store.getters['menu/general'])
    const streams = computed(() => store.getters['stream/streams'])
    const title = computed(() => i18n.global.t('myCourses'))

    useMeta({
      title: title.value
    })

    const {
      bannersBeforeCourse,
      ...coursesBanners
    } = useCoursesBanners()

    function filteredBannersBeforeCourse (courseId) {
      return bannersBeforeCourse.value.filter(banner => {
        const isBannerForCourse = banner.courseIds.includes(courseId)
        const isBannerForAllCourse = banner.courseIds.length === 0
        const isShowBanner = isBannerForCourse || isBannerForAllCourse

        if (isShowBanner && (!bannersShown.value[banner.id] || bannersShown.value[banner.id] === courseId)) {
          bannersShown.value[banner.id] = courseId
          return true
        }

        return false
      })
    }

    function getMenu (stream) {
      const _menu: cMenu = {}

      if (stream.status === 'started') {
        _menu.calendar = {
          icon: '-calendar',
          name: i18n.global.t('courseCalendar'),
          link: `/course/${stream.id}/stages`
        }
      }

      if (stream.instructionUrl) {
        _menu.manual = {
          icon: '-manual',
          name: i18n.global.t('courseInstruction'),
          link: stream.instructionUrl,
          type: 'link',
          target: '-blank'
        }
      }

      if (stream.chatChannelId && stream.isExpertMessageEnable) {
        _menu.expertChat = {
          icon: '-chat',
          name: i18n.global.t('messages'),
          link: `/chat/${stream.chatChannelId}`,
          note: ''
        }

        if (stream.statistic) {
          _menu.expertChat.note = stream.statistic.expertChatMessageCount
        }
      }

      return _menu
    }

    function onSelectMenuItem (item) {
      if (item?.link?.name === 'courses') {
        isOpenMenu.value = false
        setTimeout(() => {
          const offsetTop = refContainerCourses.value?.$el?.offsetTop || 0
          window.scroll({
            top: offsetTop,
            behavior: 'smooth'
          })
        }, 100)
      }
    }

    const coursesMapped = computed(() => {
      const _courses: {
        course: Course;
        stream: Stream;
        menu: cMenu;
        banners: Banner[];
      }[] = []

      Object.values(streams.value).forEach(stream => {
        _courses.push({
          course: courses.value[stream.courseId],
          stream,
          menu: getMenu(stream),
          banners: filteredBannersBeforeCourse(stream.courseId)
          // type: 'stream'
        })
      })

      return _courses.sort((a, b) => {
        const order = [StreamStatus.NOT_STARTED, StreamStatus.OPEN, StreamStatus.FREEZE, StreamStatus.STOPPED, StreamStatus.ENDED]
        return order.indexOf(a.stream.status) - order.indexOf(b.stream.status)
      })
    })

    function showErrorAlert () {
      Swal.fire({
        icon: 'error',
        title: i18n.global.t('error.error'),
        text: i18n.global.t('error.courses')
      })
    }
    function showErrorUpdateStreamStatus () {
      Swal.fire({
        icon: 'error',
        title: i18n.global.t('error.error'),
        text: i18n.global.t('error.coursesUpdate')
      })
    }
    async function fetchData () {
      await store.dispatch('stream/fetchUserCourseStreamsCached')

      store.dispatch('stream/fetchUpdateUserStreamsStatus')
        .catch(e => {
          if (route.name === 'courses') {
            showErrorUpdateStreamStatus()
          }
          Logger.error(e)
        })
    }

    async function init () {
      let coursesPageLoading = performance.now()
      const startLoading = Date.now()
      try {
        loaded.value = false
        fetchArticles()
          .catch(e => {
            sentry.captureException(e)
            Logger.error(e)
          })
        await fetchData()
      } catch (e) {
        sentry.captureException(e)
        if (route.name === 'courses') {
          showErrorAlert()
        }
        Logger.error(e)
      } finally {
        coursesPageLoading = performanceEnd(coursesPageLoading)
        Logger.info(`Courses loaded: ${coursesPageLoading} ms`)
        Analytics.send({
          category: 'ux.performance',
          action: 'load_courses',
          label: coursesPageLoading.toString()
        })
        loaded.value = true
        FirebasePerformance.record({
          traceName: 'load_courses',
          startTime: startLoading
        })
      }
    }

    onMounted(async () => {
      await init()
    })

    return {
      status,
      bannersShown,
      isOpenMenu,
      refContainerCourses,

      username,
      user,
      title,
      loaded,
      coursesMapped,
      menu,
      onSelectMenuItem,

      articles,
      isShowArticles,
      previewLoaded,
      ...coursesBanners
    }
  }
})
