import { computed, ref } from 'vue'
import OneSignal from '@web/common/OneSignal'
import Swal from 'sweetalert2'
import { useStore } from '@web/store'
import i18n from '@web/plugins/i18n'

function usePushSubscribe () {
  const store = useStore()
  const subscribed = ref(false)
  const user = computed(() => store.getters['user/user'])

  const btnText = computed<string>(() => !subscribed.value ? i18n.global.t('Subscribe') : i18n.global.t('Unsubscribe'))

  function checkSubscribed () {
    const oneSignalRequest: [Promise<boolean>, Promise<string>] = [
      OneSignal.isPushNotificationsEnabled(),
      OneSignal.getExternalUserId()
    ]

    return Promise.all(oneSignalRequest)
      .then((response) => {
        const [isEnabled, userId] = response
        subscribed.value = isEnabled && userId === String(user.value.id)
      })
  }

  function checkPermission () {
    OneSignal.getNotificationPermission()
      .then(state => {
        // в хроме возвращает "default" даже если пуш-уведомления запрещены
        if (state === 'denied') {
          Swal.fire({
            title: i18n.global.t('error.error'),
            text: i18n.global.t('error.pushPermissionWeb'),
            showConfirmButton: false,
            icon: 'error'
          })
        }
      })
  }

  const subscribe = () => {
    OneSignal.registerForPushNotifications()
    OneSignal.setSubscription(true)
    OneSignal.setExternalUserId(user.value.id)
  }

  const unsubscribe = () => {
    OneSignal.setSubscription(false)
  }

  function toggleSubscribe () {
    OneSignal.getSubscriptionState()
      .then(state => {
        if (state.isPushEnabled) {
          /* Subscribed, opt them out */
          unsubscribe()
        } else {
          subscribe()
        }
      })

    checkPermission()
  }

  const isOneSignalEnable = computed<boolean>(() => {
    return store.getters['assets/isOneSignalEnable'] && OneSignal.isPushNotificationsSupported()
  })
  try {
    OneSignal.on('notificationPermissionChange', checkSubscribed)
    OneSignal.on('subscriptionChange', checkSubscribed)
    checkSubscribed()
  } catch (e) {
    // если OneSignal заблокировал блокировщик рекламы
  }

  return {
    subscribed,
    isOneSignalEnable,
    btnText,
    toggleSubscribe,
    subscribe,
    unsubscribe,
    checkSubscribed
  }
}

export default usePushSubscribe
