import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "lCommon coursePage" }
const _hoisted_2 = { class: "articles_title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_push_prompt = _resolveComponent("as-push-prompt")!
  const _component_as_header_mobile = _resolveComponent("as-header-mobile")!
  const _component_as_go_to_community = _resolveComponent("as-go-to-community")!
  const _component_as_article_card = _resolveComponent("as-article-card")!
  const _component_as_article_container = _resolveComponent("as-article-container")!
  const _component_as_preloader = _resolveComponent("as-preloader")!
  const _component_as_container = _resolveComponent("as-container")!
  const _component_as_course_card = _resolveComponent("as-course-card")!
  const _component_as_empty = _resolveComponent("as-empty")!
  const _component_as_banners_catalog = _resolveComponent("as-banners-catalog")!
  const _component_mq_responsive = _resolveComponent("mq-responsive")!
  const _component_as_header = _resolveComponent("as-header")!
  const _component_as_menu = _resolveComponent("as-menu")!
  const _component_as_banners_top = _resolveComponent("as-banners-top")!
  const _component_as_courses_content = _resolveComponent("as-courses-content")!
  const _component_as_row = _resolveComponent("as-row")!
  const _component_as_spacer = _resolveComponent("as-spacer")!
  const _component_as_breadcrumb = _resolveComponent("as-breadcrumb")!
  const _component_as_header_main = _resolveComponent("as-header-main")!
  const _component_as_banner = _resolveComponent("as-banner")!
  const _component_as_title = _resolveComponent("as-title")!
  const _component_as_footer = _resolveComponent("as-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_as_push_prompt),
    _createVNode(_component_mq_responsive, { target: "mobile" }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_mobile, {
          title: _ctx.username,
          avatar: _ctx.user.avatar,
          class: "-courses",
          "onMenu:toggle": _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpenMenu = $event)),
          "onItem:select": _ctx.onSelectMenuItem
        }, null, 8, ["title", "avatar", "onItem:select"]),
        (_ctx.isShowArticles)
          ? _withDirectives((_openBlock(), _createBlock(_component_as_container, {
              key: 0,
              class: "articles"
            }, {
              title: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", null, _toDisplayString(_ctx.$t('Community')), 1),
                  _createElementVNode("div", null, [
                    _createVNode(_component_as_go_to_community, {
                      as: "link",
                      text: _ctx.$t('View all')
                    }, null, 8, ["text"])
                  ])
                ])
              ]),
              content: _withCtx(() => [
                (_ctx.previewLoaded)
                  ? (_openBlock(), _createBlock(_component_as_article_container, { key: 0 }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.articles, (article) => {
                          return (_openBlock(), _createBlock(_component_as_article_card, {
                            article: article,
                            key: article.id
                          }, null, 8, ["article"]))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_as_preloader, { key: 1 }))
              ]),
              _: 1
            }, 512)), [
              [_vShow, !_ctx.isOpenMenu]
            ])
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_as_container, { ref: "refContainerCourses" }, {
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('courseContinue')), 1)
          ]),
          content: _withCtx(() => [
            (_ctx.loaded)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coursesMapped, (item) => {
                    return (_openBlock(), _createBlock(_component_as_course_card, {
                      key: item.stream.id,
                      course: item.course,
                      stream: item.stream
                    }, null, 8, ["course", "stream"]))
                  }), 128)),
                  (_ctx.coursesMapped.length === 0)
                    ? (_openBlock(), _createBlock(_component_as_empty, { key: 0 }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('notFoundCourses')), 1),
                          _createElementVNode("button", {
                            class: "btnCatalog",
                            onClick: _cache[1] || (_cache[1] = () => _ctx.window.location.href = '/')
                          }, _toDisplayString(_ctx.$t('openCatalogCourses')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(), _createBlock(_component_as_preloader, { key: 1 }))
          ]),
          _: 1
        }, 512), [
          [_vShow, !_ctx.isOpenMenu]
        ]),
        _withDirectives(_createVNode(_component_as_banners_catalog, {
          banners: _ctx.bannersCatalog,
          position: _ctx.bannersPosition
        }, null, 8, ["banners", "position"]), [
          [_vShow, !_ctx.isOpenMenu]
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_mq_responsive, { target: "tablet" }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header, {
          back: false,
          title: _ctx.username,
          subtitle: _ctx.user.city
        }, null, 8, ["title", "subtitle"]),
        _createVNode(_component_as_row, null, {
          sidebar: _withCtx(() => [
            _createVNode(_component_as_menu, {
              menu: _ctx.menu,
              status: _ctx.status
            }, null, 8, ["menu", "status"])
          ]),
          content: _withCtx(() => [
            (_ctx.loaded)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_as_banners_top, {
                    banners: _ctx.bannersTop,
                    position: _ctx.bannersPosition,
                    placement: _ctx.bannersPlacements.top,
                    platform: _ctx.bannersPlatforms.desktop
                  }, null, 8, ["banners", "position", "placement", "platform"]),
                  (_ctx.loaded)
                    ? (_openBlock(), _createBlock(_component_as_courses_content, {
                        key: 0,
                        "courses-streams": _ctx.coursesMapped
                      }, null, 8, ["courses-streams"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_as_banners_top, {
                    banners: _ctx.bannersBottom,
                    position: _ctx.bannersPosition,
                    placement: _ctx.bannersPlacements.bottom,
                    platform: _ctx.bannersPlatforms.tablet
                  }, null, 8, ["banners", "position", "placement", "platform"])
                ], 64))
              : (_openBlock(), _createBlock(_component_as_preloader, { key: 1 }))
          ]),
          _: 1
        }),
        _createVNode(_component_as_spacer)
      ]),
      _: 1
    }),
    _createVNode(_component_mq_responsive, {
      class: "lCommon bg-blue",
      target: "desktop"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_main, null, {
          breadcrumb: _withCtx(() => [
            _createVNode(_component_as_breadcrumb, {
              title: _ctx.$t('myCourses')
            }, null, 8, ["title"])
          ]),
          _: 1
        }),
        _createVNode(_component_as_row, null, {
          menu: _withCtx(() => [
            _createVNode(_component_as_menu, {
              menu: _ctx.menu,
              type: "sidebar"
            }, null, 8, ["menu"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bannersSidebar, (banner, i) => {
              return (_openBlock(), _createBlock(_component_as_banner, {
                key: `bannerSidebar${i}`,
                banner: banner,
                platform: _ctx.bannersPlatforms.desktop,
                position: _ctx.bannersPosition,
                placement: _ctx.bannersPlacements.sidebar
              }, null, 8, ["banner", "platform", "position", "placement"]))
            }), 128))
          ]),
          content: _withCtx(() => [
            _createVNode(_component_as_banners_top, {
              banners: _ctx.bannersTop,
              position: _ctx.bannersPosition,
              placement: _ctx.bannersPlacements.top,
              platform: _ctx.bannersPlatforms.desktop
            }, null, 8, ["banners", "position", "placement", "platform"]),
            _createVNode(_component_as_row, { class: "-inner" }, {
              content: _withCtx(() => [
                _createVNode(_component_as_title, { class: "-withoutShadow" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.title), 1)
                  ]),
                  _: 1
                }),
                (_ctx.loaded)
                  ? (_openBlock(), _createBlock(_component_as_courses_content, {
                      key: 0,
                      "courses-streams": _ctx.coursesMapped
                    }, null, 8, ["courses-streams"]))
                  : (_openBlock(), _createBlock(_component_as_preloader, { key: 1 }))
              ]),
              _: 1
            }),
            _createVNode(_component_as_banners_top, {
              banners: _ctx.bannersBottom,
              position: _ctx.bannersPosition,
              placement: _ctx.bannersPlacements.bottom,
              platform: _ctx.bannersPlatforms.desktop
            }, null, 8, ["banners", "position", "placement", "platform"])
          ]),
          _: 1
        }),
        _createVNode(_component_as_spacer),
        _createVNode(_component_as_footer)
      ]),
      _: 1
    })
  ]))
}