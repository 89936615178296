import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "asModalCriticalRenew_text" }
const _hoisted_2 = { class: "asModalCriticalRenew_subText" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_modal = _resolveComponent("as-modal")!

  return (_openBlock(), _createBlock(_component_as_modal, {
    modelValue: true,
    "btn-close": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["asModalCriticalRenew", _ctx.className])
      }, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.text) + " :( ", 1),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('renewAccessBestPrice')), 1),
        (_ctx.isShowRenewBtn)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "asModalCriticalRenew_btn -renew",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handlerRenew && _ctx.handlerRenew(...args)))
            }, _toDisplayString(_ctx.renewBtnText), 1))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          class: "asModalCriticalRenew_btn -remind",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handlerRemind && _ctx.handlerRemind(...args)))
        }, _toDisplayString(_ctx.$t('remindMeLater')), 1)
      ], 2)
    ]),
    _: 1
  }))
}