
import { defineComponent } from 'vue'
import { MqResponsive } from 'vue3-mq'

import AsCourses from '@web/components/AsCourses.vue'
import AsCoursePreview from '@web/components/AsCoursePreview.vue'
import AsRenewCourse from '@web/components/AsRenewCourse.vue'
import AsEmpty from '@web/components/AsEmpty.vue'
import AsButton from '@web/components/AsButton.vue'

export default defineComponent({
  name: 'AsCoursesContent',
  components: {
    MqResponsive,
    AsCourses,
    AsCoursePreview,
    AsRenewCourse,
    AsEmpty,
    AsButton
  },
  props: {
    coursesStreams: {
      type: Array,
      required: true
    }
  }
})
