import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["asNoteCountDay", {
      '-critical': _ctx.isEnded || _ctx.isCritical,
      '-warning': _ctx.isWarning,
      '-freeze': _ctx.isFreezing
    }])
  }, _toDisplayString(_ctx.text), 3))
}