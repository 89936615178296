
import { defineComponent, onMounted, ref } from 'vue'

import Cookies from 'js-cookie'

import AsModal from '@web/components/AsModal.vue'
import i18n from '@web/plugins/i18n'
import usePushSubscribe from '@web/composition/usePushSubscribe'

export default defineComponent({
  components: {
    AsModal
  },
  setup () {
    const isOpen = ref(false)

    const {
      isOneSignalEnable,
      subscribed,
      checkSubscribed,
      subscribe,
      unsubscribe
    } = usePushSubscribe()

    const userAnswer = {
      _key: 'acProject_push_answer',
      get (): string | null {
        // return sessionStorage.getItem(this._key)
        return Cookies.get(this._key)
      },
      set (value: string): void {
        // sessionStorage.setItem(this._key, value)
        Cookies.set(this._key, value, { sameSite: 'Strict', expires: 7 })
      }
    }

    onMounted(async () => {
      if (isOneSignalEnable.value && !userAnswer.get()) {
        await checkSubscribed()
        setTimeout(() => {
          isOpen.value = !subscribed.value
        }, 10000)
      }
    })

    function declinePush () {
      isOpen.value = false
      unsubscribe()
      userAnswer.set('no')
    }

    function enablePush () {
      isOpen.value = false
      subscribe()
      userAnswer.set('yes')
    }

    return {
      isOpen,
      title: i18n.global.t('OneSignal.slidedown.actionTitle'),
      text: i18n.global.t('OneSignal.slidedown.actionText'),
      declinePush,
      enablePush
    }
  }
})
