
import { defineComponent, computed } from 'vue'
import AsNoteCountDay from '@web/components/AsNoteCountDay.vue'
import i18n from '@web/plugins/i18n'
import { useStore } from '@web/store'
import { useRouter } from 'vue-router'
import STREAM_STATUS from '@web/consts/StreamStatus'
import Swal from 'sweetalert2'

export default defineComponent({
  components: {
    AsNoteCountDay
  },
  props: {
    courseStream: {
      type: Object,
      default: () => ({})
    },
    loaded: {
      type: Boolean,
      default: () => true
    }
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()

    const style = computed(() => {
      let style = {}
      if (props.courseStream.blur) {
        style = { filter: `blur(${props.courseStream.course.blur})` }
      }
      return style
    })
    const progress = computed(() => {
      if (!props.courseStream.stream.progress) {
        return ''
      }
      const progress = props.courseStream.stream.progress.progress
      if (typeof progress === 'number') {
        return progress < 100 ? `${progress}% ` + i18n.global.t('progress') : i18n.global.t('courseEnded')
      }

      return progress
    })
    const canContinueCourse = computed(() => props.courseStream.stream.status === STREAM_STATUS.OPEN)
    const showUnfreezeButton = computed(() => props.courseStream.stream.status === STREAM_STATUS.FREEZE)
    const continueUrl = computed(() => {
      const route = {
        name: 'calendar',
        params: {
          courseId: props.courseStream.stream.courseId,
          streamId: props.courseStream.stream.id
        }
      }
      return canContinueCourse.value ? route : ''
    })
    const courseBackground = computed<string | undefined>(() => props.courseStream.course?.background?.medium)
    const courseImage = computed<string | undefined>(() => props.courseStream.course?.image?.small)

    const isShowAccessText = computed(() => {
      if (props.courseStream.stream.status === STREAM_STATUS.NOT_STARTED) {
        return true
      }
      return !props.courseStream.stream.paymentSubscriptionId
    })

    function goToContinue () {
      const route = {
        name: 'calendar',
        params: {
          courseId: String(props.courseStream.stream.courseId),
          streamId: String(props.courseStream.stream.id)
        }
      }

      router.push(route)
    }

    async function unfreezeCourse () {
      const result = await Swal.fire({
        title: i18n.global.t('areYouSureUnfreeze'),
        confirmButtonText: i18n.global.t('sure'),
        cancelButtonText: i18n.global.t('no'),
        showCancelButton: true
      })
      if (result.isConfirmed) {
        await store.dispatch('stream/unfreeze', {
          streamId: props.courseStream.stream.id
        })
        goToContinue()
      }
    }

    return {
      isShowAccessText,
      canContinueCourse,
      showUnfreezeButton,
      continueUrl,
      style,
      progress,
      courseBackground,
      courseImage,

      goToContinue,
      unfreezeCourse
    }
  }
})
