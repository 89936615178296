
import { defineComponent } from 'vue'

import AsIconBase from '../AsIconBase.vue'

export default defineComponent({
  components: {
    AsIconBase
  }
})
