import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "asCourses" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.coursesStreams, (courseStream, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "asCourses_item",
        key: i
      }, [
        _renderSlot(_ctx.$slots, "default", { courseStream: courseStream }, () => [
          _createTextVNode(_toDisplayString(courseStream), 1)
        ])
      ]))
    }), 128))
  ]))
}