import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e762a4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isShow)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: _ctx.url,
        class: _normalizeClass(`asGoToCommunity -${_ctx.as}`)
      }, _toDisplayString(_ctx.text), 11, _hoisted_1))
    : _createCommentVNode("", true)
}