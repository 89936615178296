
import { defineComponent, onMounted } from 'vue'
import { showCommunityInPlatform, scrollCommunityInPlatform } from '@web/services/Analytics/events'

export default defineComponent({
  name: 'asArticleContainer',
  setup () {
    onMounted(() => {
      showCommunityInPlatform()
    })

    return {
      scrollArticles: scrollCommunityInPlatform
    }
  }
})
