
import { defineComponent, computed } from 'vue'

import { getNoteCountDayRemaining } from '@web/common/Utils'
import STREAM_STATUS from '@web/consts/StreamStatus'
import { Stream } from '@web/store/types/modules/stream'
import i18n from '@web/plugins/i18n'
import useStatusRenew from '@web/composition/useStatusRenew'

export default defineComponent({
  props: {
    stream: {
      type: Object as () => Stream,
      required: true
    }
  },
  setup (props) {
    const {
      isEnded,
      isWarning,
      isCritical,
      isFreezing
    } = useStatusRenew(props.stream)

    const text = computed(() => {
      if (props.stream.status !== STREAM_STATUS.ENDED && props.stream.status !== STREAM_STATUS.STOPPED) {
        return getNoteCountDayRemaining(props.stream, true)
      }

      if (window.innerWidth <= 828) {
        return i18n.global.t('courseRenew')
      }

      if (props.stream.status === STREAM_STATUS.STOPPED) {
        return i18n.global.t('accessStopped')
      }

      return i18n.global.t('accessEnded')
    })

    return {
      isEnded,
      isWarning,
      isCritical,
      isFreezing,
      text
    }
  }
})
