
import { defineComponent, computed } from 'vue'

import { Stream } from '@web/store/types/modules/stream'
import { getNoteCountDayRemaining } from '@web/common/Utils'
import AsModal from '@web/components/AsModal.vue'
import { EXTEND_MODEL } from '@web/consts/CourseExtentends'
import i18n from '@web/plugins/i18n'

export default defineComponent({
  props: {
    stream: {
      type: Object as () => Stream,
      required: true
    },
    course: {
      type: Object as () => Course,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  emits: ['close', 'click:renew', 'click:remind'],
  components: {
    AsModal
  },
  setup (props, ctx) {
    const isShowRenewBtn = computed(() => props.course?.extendModel !== EXTEND_MODEL.DISABLED_EXTEND)
    const renewBtnText = computed(() => props.course?.extendModel === EXTEND_MODEL.NEW_BUY_EXTEND ? i18n.global.t('renew') : i18n.global.t('renew'))

    function handlerRenew () {
      ctx.emit('click:renew')
    }

    function handlerRemind () {
      ctx.emit('click:remind')
    }

    return {
      isShowRenewBtn,
      renewBtnText,
      text: getNoteCountDayRemaining(props.stream, true),
      handlerRenew,
      handlerRemind,
      close
    }
  }
})
