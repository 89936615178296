
import { defineComponent } from 'vue'
import { RouteLocationRaw, useRouter } from 'vue-router'
import AsMenuModal from '@web/components/AsMenuModal.vue'

export default defineComponent({
  props: {
    title: {
      title: String,
      default: () => ''
    },
    subtitle: {
      title: String,
      default: () => ''
    },
    background: {
      type: String,
      default: () => ''
    },
    back: {
      type: Boolean,
      default: () => true
    },
    nav: {
      type: Boolean,
      default: () => true
    },
    prevPage: {
      type: [String, Object]
    }
  },
  setup (props) {
    const router = useRouter()

    return {
      goBack () {
        if (props.prevPage) {
          router.push(props.prevPage as RouteLocationRaw)
        } else {
          router.go(-1)
        }
      }
    }
  },
  components: {
    AsMenuModal
  }
})
